import MainBanner2 from "../components/MainBanner2";
import Header from "../components/Header";
import Footer2 from "../components/Footer2";
import Home2OurBlog from '../components/Home2OurBlog'
import Home2SpacialMenu from "../components/Home2SpacialMenu";

const Home2 = ({restaurant}) => {


  return (
    <div className="page-content bg-white ">
        <Header />
      <MainBanner2 />
      <Home2OurBlog /> 
      <Home2SpacialMenu />
      <Footer2 />
    </div>
  );
};

export default Home2;
