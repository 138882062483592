/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import logo from "../assets/images/logo.jpg";
import logo2 from "../assets/images/logo2.png";

import background from "../assets/images/bk.png"
import background1 from '../assets/images/background/chefhouseback.jpg'
import sushi1 from '../assets/images/background/sushi1.jpg'
import sushi2 from '../assets/images/background/sushi2.jpg'
import sushi3 from '../assets/images/background/sushi3.jpg'
import sushi4 from '../assets/images/background/sushi4.jpg'
import cake from '../assets/images/client/cake.jpg'
import cake1 from '../assets/images/client/cake1.jpg'
import cake2 from '../assets/images/client/cake2.jpg'
import cake3 from '../assets/images/client/cake3.jpg'
import hours from '../assets/images/client/hour.jpg'
import drinkmenu from '../assets/images/client/drinkmenu.jpg'
import cakemenu from '../assets/images/client/cakemenu.jpg'
import drink1 from '../assets/images/client/drink1.png'
import new1 from '../assets/images/client/new1.jpg'
import new2 from '../assets/images/client/new2.jpg'
import new3 from '../assets/images/client/new3.jpg'
import new4 from '../assets/images/client/new4.jpg'
import new5 from '../assets/images/client/new5.jpg'
import new6 from '../assets/images/client/new6.jpg'
import new7 from '../assets/images/client/new7.jpg'
import new8 from '../assets/images/client/new8.jpg'
import new9 from '../assets/images/client/new9.jpg'
import new10 from '../assets/images/client/new10.jpg'

export const IMAGES = {

  logo: logo,
  logo2: logo2,
    background: background,
    background1: background1,
    sushi1: sushi1,
    sushi2: sushi2,
    sushi3: sushi3,
    suhi4: sushi4,
    cake: cake,
    cake1: cake1,
    cake2: cake2,
    cake3: cake3,
    hours: hours,
    drinkmenu: drinkmenu,
    cakemenu: cakemenu,
    drink1: drink1,
    new1: new1,
    new2: new2,
    new3: new3,
    new4: new4,
    new5: new5,
    new6: new6,
    new7: new7,
    new8: new8,
    new9: new9,
    new10: new10,

}
