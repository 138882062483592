const apiUrl = process.env.REACT_APP_API_URL;

const GET_FREEITEMS = 'freeitems/GET_FREEITEMS';

const getFreeItems = (freeitems) => ({
    type: GET_FREEITEMS,
    freeitems
});

export const getFreeItemsThunk = (restaurant_id) => async (dispatch) => {
    const response = await fetch(`${apiUrl}/api/freeitems/restaurant/${restaurant_id}`);

    if (response.ok) {
        const data = await response.json();
        dispatch(getFreeItems(data));
        return data;
    }
};

const initialState = {
    freeitems: []
};

const freeitemsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_FREEITEMS:
            return {
                ...state,
                freeitems: action.freeitems
            };
        default:
            return state;
    }
};

export default freeitemsReducer;
