import { Link } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import { Modal, Button } from "react-bootstrap";
import { Context } from "../context/AppContext";
import { checkIfLunchTime } from "../Utilts/helper";

const MenuStyle4 = ({restaurant}) => {
  const [active, setActive] = useState(0);
  const cardRef = useRef([]);
  const [hoverActive, setHoverActive] = useState(-1);
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null); // State to hold selected item
  const [modalVisible, setModalVisible] = useState(false); // State to control modal visibility
  const { language } = useContext(Context)

  const handleCardClick = (item) => {
    setSelectedItem(item);
    setModalVisible(true);
  };


  useEffect(() => {
    if (restaurant?.Categories) {
      let allItems = [];
      if (active === 0) {
        // "All" selected, show all items, but filter out hidden items
        allItems = restaurant.Categories.flatMap(category => 
          category.Items.filter(item => !item?.hidden) // Filter out items where hidden is true
        );
          
      } else {
        // Show items of the selected category, but filter out hidden items
        allItems = restaurant.Categories[active - 1]?.Items.filter(item => !item?.hidden) || [];

      }
      setFilteredItems(allItems);
    }
  }, [active, restaurant]);
  
  return (
    <div className="page-content bg-white">
      <Header />

      <section className="content-inner">
      <div className="row mt-3 pl-2 pr-2 justify-content-center" 
                style={{
                  position: 'sticky',
                  top: 64,
                  zIndex: 1000, // Adjust as needed
                  background: 'white', // Add background color if needed
                  width: '100%',
                }}
>
            <div className="col-xl-10 col-lg-9 col-md-12">
              <div
                className="site-filters style-1 clearfix"
                              >
                <ul className="filters">
                  <li
                    className={active === 0 ? "active" : ""}
                    onClick={() => setActive(0)}
                  >
                    <Link to="#">All</Link>
                  </li>
                  {restaurant?.Categories &&
                    restaurant.Categories.map(({ name }, id) => (
                      <li
                        className={active === id + 1 ? "active" : ""}
                        key={id}
                        onClick={() => {
                          setActive(id + 1);
                        }}
                      >
                        <Link to="#">{name}</Link>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        <div className="container">
          

          <ul id="masonry" className="row">
            {filteredItems.map(({ id, name, description, price, image_url, Restaurant_Item_Attributes, chinese_name }) => (
              <li
                className="card-container col-xl-3 col-lg-4 col-md-6 m-b30 "
                onClick={() => handleCardClick({ id, name, description, price, image_url, Restaurant_Item_Attributes, chinese_name })}
                style={{ transition: "all .2s" }}
                key={id}
                ref={(node) => {
                  if (node) {
                    cardRef.current.push(node);
                  }
                }}
              >
                <div
                  className={`dz-img-box style-4 box-hover ${hoverActive === id ? "active" : ""}` }
                  style={{ cursor: "pointer" }}
                >
                  <div className="menu-detail">
                  {image_url && (
                      <div className="dz-media">
                        <img src={image_url} alt="/" />
                      </div>
                        )}
                    <div className="dz-content">
                      <h5 className="title">
                      <Link style={{fontSize:'18px'}}>
  {language === 'en' ? (name && name.length > 43 ? name.slice(0, 43) + '...' : name) : chinese_name}
</Link>
                      </h5>
                      {/* <p>{description.slice(0, 17) + "..."}</p> */}
                    </div>
                  </div>
                  <div className="menu-footer">
                    <span>Regular Price</span>
                    <h5 className="price">{price}</h5>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <Modal show={modalVisible} onHide={() => setModalVisible(false)} size="lg">
  <Modal.Header closeButton>
    <Modal.Title>{language === 'en' ? selectedItem?.name : selectedItem?.chinese_name } </Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <div className="d-flex">
      <div className="mr-5 hover-zoom">
        {selectedItem?.image_url && (
        <img
          src={selectedItem?.image_url}
          alt={language === 'en' ? selectedItem?.name : selectedItem?.chinese_name }
          className="img-fluid"
          style={{ minWidth: '400px', maxWidth: '400px', maxHeight: '400px', minHeight: '400px' }}
        />
        )}
      </div>
      <div className="m-2">
        {selectedItem?.description && selectedItem?.description !== 'null' &&(
        <p style={{ fontWeight: 'bold' }}>Description: {selectedItem?.description}</p>
  )}
        <p style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>Price: {selectedItem?.price}</p>
      </div>
    </div>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setModalVisible(false)}>Close</Button>
  </Modal.Footer>
</Modal>
        </div>
      </section>
    </div>
  );
};

export default MenuStyle4;
