import { Link, useNavigate } from "react-router-dom";
import { IMAGES } from "../constent/theme";
import video from '../assets/video/video3.mp4';
import video1 from '../assets/video/video4.mp4';
import poster2 from '../assets/images/vidposter2.png';

const Home2OurBlog = () => {
  const navigate = useNavigate();

  return (
    <section className="content-inner overflow-hidden">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 m-b30 wow fadeInUp">
            <div
              className="dz-card style-3 dz-card-large"
              style={{ backgroundImage: `url(${IMAGES.blog_large_pic1})` }}
            >
              <video autoPlay loop muted playsInline  >
                <source src={video} type="video/mp4"  />
              </video>
              <div className="dz-info">
                <h3 className="dz-title">
                  <Link to="/online-order" className="text-white">
                  Fresh Lemon Tea
                  </Link>
                </h3>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 m-b30 wow fadeInUp">
            <div
              className="dz-card style-3 dz-card-large"
              style={{ backgroundImage: `url(${IMAGES.blog_large_pic1})` }}
            >
              <video autoPlay loop muted playsInline  >
                <source src={video1} type="video/mp4"  />
              </video>
              <div className="dz-info">
                <h3 className="dz-title">
                  <Link to="/online-order" className="text-white">
                  Fresh Milk Tea
                  </Link>
                </h3>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default Home2OurBlog;
