import { Link, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../context/AppContext";
import { useDispatch, useSelector } from "react-redux";
import { getOnlineStatusThunk } from "../store/restaurants";
import AddToCartModal from "../elements/AddToCartModal";
import Header from "../components/Header";
import { isStoreOpen, showTimeError } from "../Utilts/helper";
import Swal from "sweetalert2";
import { getFreeItemsThunk } from "../store/freeitems";
import { ProgressBar, Button, Modal, Col, Row, Card, Container, Collapse} from 'react-bootstrap';
import AddFreeItemModal from "../elements/AddFreeItemModal";
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const OnlineOrder = ({restaurant}) => {
  const [active, setActive] = useState(0);
  const dispatch = useDispatch()
  const cardRef = useRef([]);
  const {  language, changedCart } = useContext(Context);
  const [hoverActive, setHoverActive] = useState(-1);
  const [modal, setModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); // Add this state
  const [filteredItems, setFilteredItems] = useState([]);
  const restaurantStatus = useSelector((state) => state.restaurant.onlineStatus);
  const navigate = useNavigate();
  const [currentCart, setCurrentCart] = useState([]);
  const freeItems = useSelector((state) => state.freeitems.freeitems);
  const [freeItemModal, setFreeItemModal] = useState(false);
  const [addFreeItemModal, setAddFreeItemModal] = useState(false);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("cart"));

    if(items){ 
      setCurrentCart(items);
    }
  }, [changedCart]);

  useEffect(() => { 
    dispatch(getOnlineStatusThunk(4))
    dispatch(getFreeItemsThunk(4))
  }, [dispatch])

  useEffect(() => {
    const handleScroll = () => {
      const categoryElements = restaurant?.Categories.map((_, id) =>
        document.getElementById(`category-${id + 1}`)
    );
    
    const scrollPosition = window.scrollY + window.innerHeight / 2;
    let activeCategory = 0;
    
    categoryElements.forEach((element, index) => {
      if (element) {
        const rect = element.getBoundingClientRect();
        const offsetTop = rect.top + window.scrollY;
        
        if (scrollPosition >= offsetTop) {
          activeCategory = index + 1;
        }
      }
      });
      
      setActive(activeCategory);
    };
    
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [restaurant]);



  const handleOpenAddToCartModal = (item) => {
    if(restaurantStatus?.stop_order){ 
      Swal.fire({
        icon: 'error',
        title: 'Restaurant Too Busy',
        text: 'Online order is currently closed!',
      })
      return;
    }

    if(isStoreOpen() === false ){
      showTimeError();
    } else {
      setSelectedItem(item); // Set the selected item
      setModal(true);
    }
  };

  const calcuateCurrentCart = () => {
    let subtotal = currentCart.reduce(
      (total, cartItem) => total + cartItem.Item.price * cartItem.quantity,
      0
    );

    currentCart.forEach((cartItem) => {
      cartItem.options.forEach((option) => {
        subtotal += option.priceModifier * cartItem.quantity;
      });
    });

    return subtotal.toFixed(2);
  }

  const displayFreeItem = () => { 
    for(let i = 0; i < freeItems.length; i++){ 
      if(calcuateCurrentCart() <= freeItems[i].need_amount){ 
        return freeItems[i];
      }
      return freeItems[freeItems.length - 1];
    }
  }

  const handleOpenAddFreeItemModal = (item) => {
    if (ifFreeItemALreadyExists()) {
      setFreeItemModal(false);

      Swal.fire({
        icon: 'error',
        title: 'Free Item Already Exists',
        text: 'You already have a free item in your cart! Remove it from cart to add another one.',
        showCancelButton: true,
        cancelButtonText: 'Go To Cart',
        confirmButtonText: 'Ok',
        cancelButtonColor: 'green',
        customClass: {
          popup: 'swal2-custom-z-index'
        }
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.cancel) {
          // Handle the "Cart" button click
          navigate('/checkout');
        }
        // No need to handle "Ok" button click explicitly, as it just closes the modal
      });
    } else {
      setSelectedItem(item.Item); // Set the selected item
      setAddFreeItemModal(true);
    }
  };

  const handleCheckSameFreeItem = (item) => {
    const cart = JSON.parse(localStorage.getItem("freeItem")) || {};
    if(cart?.Item?.name == item.Item.name){ 
      return true;
    }
    return false
  }

  const ifFreeItemALreadyExists = () => { 
    const cart = JSON.parse(localStorage.getItem("freeItem")) || {};
    
    if(cart?.Item?.name){ 
      return true;
    }
    return false
  }

  
  // Function to calculate offset of category name relative to viewport
  const getCategoryOffset = (categoryId) => {
    const categoryElement = document.getElementById(`category-${categoryId}`);
    if (categoryElement) {
      const rect = categoryElement.getBoundingClientRect();
      return rect.top + window.scrollY - 200;
    }
    return 0;
  };

  return (
    <div className="page-content bg-white">
      <Header />

      <section className="content-inner">
      <div className="category-menu-wrapper container">
          <div className="category-menu">
            <ul className="list-unstyled d-flex align-items-center mb-0">
           
              {restaurant?.Categories &&
                restaurant.Categories.map(({ name }, id) => (
                  <li
                    className={`mr-3 p-2 rounded-pill category-item ${
                      active === id + 1 ? "active" : ""
                    }`}
                    key={id}
                    onClick={() => {
                      setActive(id + 1);
                      // Calculate offset of category name
                      const categoryOffset = getCategoryOffset(id + 1);
                      // Scroll to the category section with an offset
                      window.scrollTo({
                        top: categoryOffset,
                        behavior: "smooth",
                      });
                    }}
                  >
                    <Link
                      to={`#category-${id + 1}`}
                      style={{ fontSize: "1.1rem" }}
                    >
                      {name}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </div>
        <div className="container">
        <ul id="masonry" className="row">
  {restaurant?.Categories &&
    restaurant.Categories.map(({ name, Items }, id) => (
      <div key={id} id={`category-${id + 1}`} className="mt-5">
        <h2>{name}</h2>
        <div className="row" style={{ overflowX: "auto" }}>
          {Items?.map(
            ({
              id,
              name,
              description,
              price,
              image_url,
              Restaurant_Item_Attributes,
              chinese_name,
            }) => (
              <li
                className="card-container col-xl-3 col-lg-4 col-md-6 m-b30"
                style={{ transition: "all .2s" }}
                key={id}
                ref={(node) => {
                  if (node) {
                    cardRef.current.push(node);
                  }
                }}
                onClick={() =>
                  handleOpenAddToCartModal({
                    id,
                    name,
                    description,
                    price,
                    image_url,
                    Restaurant_Item_Attributes,
                    chinese_name,
                  })
                }
              >
                <div
                  className={`dz-img-box style-4 box-hover ${
                    hoverActive === id ? "active" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                  onMouseEnter={() => {
                    setHoverActive(id);
                  }}
                >
                  <div className="menu-detail">
                    {image_url && (
                      <div className="dz-media">
                        <img src={image_url} alt="/" />
                      </div>
                    )}
                    <div className="dz-content">
                      <h5 className="title">
                        <Link style={{ fontSize: "18px" }}>
                          {language === "en"
                            ? name && name.length > 43
                              ? name.slice(0, 43) + "..."
                              : name
                            : chinese_name}
                        </Link>
                      </h5>
                    </div>
                  </div>
                  <div className="menu-footer">
                    <span>Regular Price</span>
                    <h5 className="price">{price}</h5>
                  </div>
                  <div className="detail-btn" style={{ cursor: "pointer" }}>
                    <i className="fa-solid fa-plus"></i>
                  </div>
                </div>
              </li>
            )
          )}
        </div>
      </div>
    ))}
</ul>
          
          <div className="row fixed-bottom text-center bg-white">
            {freeItems.length > 0 && (
            <div className="d-flex align-items-center p-3 border newshadow">
              <div className="flex-grow-1 " style={{cursor:'pointer'}} onClick={() => setFreeItemModal(true)} >
                <div className="d-flex justify-content-between align-items-center">
                  <span className="text-uppercase text-danger">Free</span>
                  <span className="m-0">{calcuateCurrentCart()}</span>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <span style={{fontWeight:'bold'}}>{displayFreeItem()?.name}</span>
                  <span className=" m-0">/{displayFreeItem()?.need_amount}</span>
                </div>
                <ProgressBar now={calcuateCurrentCart() / displayFreeItem()?.need_amount * 100}  className="mt-2" />
              </div>
              <div className="mx-3" style={{
                borderLeft: '1px solid #ccc',
                height: '100%',
                marginLeft: '15px',
                marginRight: '15px'
              }}></div>
              <div className="flex-shrink-0" onClick={() => setFreeItemModal(true)}>
                <p style={{
                  color: 'red',
                  cursor: 'pointer',
                  margin: 0,
                  whiteSpace: 'nowrap',
                  fontSize: 'small',
                  textAlign: 'center',
                  fontWeight: 'bold'
                }}>View All 
                <br /> Free Item</p>
              </div>
            </div>
            )}
            <button onClick={() => navigate('/checkout')} className="btn btn-primary hover-3">View Cart ({currentCart.length})</button>
          </div>
          <AddToCartModal item={selectedItem} modal={modal} setModal={setModal} />
        </div>
      </section>
      <Modal show={freeItemModal} onHide={() => setFreeItemModal(false)} centered scrollable>
        <Modal.Header closeButton>
          <Modal.Title>Free Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <p className="text-center text-danger">
                You can get a free item when you spend the required amount.
                <br />
                *Only one free item per order.
              </p>
            </Col>
          </Row>
          <hr  className="mt-0 mb-3"/>
          <Container>
            {freeItems.map((item, index) => {
              const isReached = calcuateCurrentCart() >= item.need_amount;

              return (
                <div key={index} className="mb-4">
                  <Row className="justify-content-center">
                    <Col xs={12}>
                      <div
                        className="d-flex justify-content-between align-items-center text-center mb-3"
                        style={{ borderBottom: '1px solid #ccc', paddingBottom: '10px', cursor: 'pointer' }}
                      >
                        <h4>{item.name}</h4>
                        <div className="d-flex align-items-center">
                          <span className="me-2">({item.ReedemableItems.length} items)</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div>
                    <Row className="justify-content-center">
                      {item?.ReedemableItems?.map((reedemableItem, reedemableIndex) => (
                        <Col key={reedemableIndex} md={4} sm={6} xs={12} className="mb-4 d-flex align-items-stretch">
                          <Card className="w-100 h-100" style={{ border: '1px solid #ccc' }}>
                            <Card.Body className="d-flex flex-column text-center">
                              {reedemableItem.Item.image_url && (
                                <Card.Img
                                  variant="top"
                                  className="align-self-center"
                                  src={reedemableItem.Item.image_url}
                                  style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '50%' }}
                                />
                              )}
                              <Card.Title>{reedemableItem.Item.name}</Card.Title>
                              <Card.Text style={{ fontWeight: 'bold', color: isReached ? 'green' : 'red' }}>
                                ${calcuateCurrentCart()} / ${item.need_amount}
                              </Card.Text>
                              <ProgressBar
                                now={(calcuateCurrentCart() / item.need_amount) * 100}
                                label={`${Math.min((calcuateCurrentCart() / item.need_amount) * 100, 100).toFixed(2)}%`}
                              />
                              <Button
                                variant={handleCheckSameFreeItem(reedemableItem) ? 'secondary' : 'primary'}
                                className="mt-2"
                                disabled={!isReached}
                                onClick={() => handleOpenAddFreeItemModal(reedemableItem)}
                              >
                                {handleCheckSameFreeItem(reedemableItem) ? 'In Cart' : 'Add to Cart'}
                              </Button>
                            </Card.Body>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </div>
              );
            })}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setFreeItemModal(false)}>
            Close
          </Button>
        </Modal.Footer>
        <AddFreeItemModal item={selectedItem} modal={addFreeItemModal} setModal={setAddFreeItemModal} />
      </Modal>
    </div>
  );
};

export default OnlineOrder;
