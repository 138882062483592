import { Link } from "react-router-dom";
import { IMAGES } from "../constent/theme";

const Home2SpacialMenu = () => {
  // Extracting the first four categories

  const items = [ 
    { 
      img: IMAGES.new10,
    },
    {
      img: IMAGES.new7,
    },
    {
      img: IMAGES.new6,
    },
    {
      img: IMAGES.new8,
    },
    {
      img: IMAGES.new1,
    },
    {
      img: IMAGES.new2,
    },
    {
      img: IMAGES.new3,
    },
    {
      img: IMAGES.new4,
    },
    {
      img: IMAGES.new5,
    },
    {
      img: IMAGES.new9,
    },
    {
      img: IMAGES.cake3,
    },
    {
      img: IMAGES.drink1,  
    },
    
  ];

  return (
    <div className="container-fluid ">
      <div className="row tea-shop-container">
        {items.map((item, index) => (
            <div className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp" key={index}>
              <div className="dz-img-box style-5">
                <div className="dz-media">
                  {item?.img && (
                  <img className="zoom-image" src={item?.img || ""} alt={item?.name} />
                  )}
                <p >{item?.name}</p>
                </div>
              </div>
            </div>
        ))}
      <div className="text-center">
        <Link to="/menu" className="btn btn-primary">View Full Menu</Link>
      </div>
      </div>
    </div>
  );
};

export default Home2SpacialMenu;
