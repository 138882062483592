import { Swiper, SwiperSlide } from "swiper/react";
import { MainBanner2Arr } from "../elements/JsonData";
import { Parallax, Pagination } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import backgroundvideo from '../assets/video/video2.mp4'
import poster from '../assets/images/vidposter1.png'
import BackgroundVideo from 'react-background-video-player'

const MainBanner2 = () => {
  const pagination = {
    clickable: true,
    el: ".main-swiper3-pagination",
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  };
  const navigate = useNavigate();
  return (
    <div className="main-bnr-three overflow-hidden top-space">
      <Swiper
        className="main-slider-3"
        direction="horizontal"
        modules={[Parallax, Pagination]}
        parallax={true}
        speed={1500}
        spaceBetween={0}
        slidesPerView={1}
        pagination={pagination}
        loop={false}
      >
        {MainBanner2Arr.map(
          (
            { title, name, price, bgimg, img1, img2, img3, img4, videoUrl }, // Assuming you have a videoUrl property in your data
            ind
          ) => (
<SwiperSlide className="" key={ind}>
  <div
    className="banner-inner overflow-hidden d-flex align-items-center justify-content-center position-relative"
    data-swiper-parallax="-10"
    data-swiper-parallax-duration="0.5"
    style={{
      position: "relative",
      borderRadius: "10px",
      minHeight: "100vh",
      overflow: "hidden",
    }}
  >
              <BackgroundVideo  poster={poster} src={backgroundvideo} className="video-background" />

  
    <Container>
      <div className="banner-content text-center">
        <span className="sub-title text-black">Lemonsweets Bakery & Cafe</span>
        <h1 className="title" style={{ fontSize: "30px" }}>Teas, Desserts, etc..</h1>
    
        <div className="banner-btn d-flex justify-content-center">
          <div
            onClick={() => navigate("/online-order")}
            className="btn btn-primary btn-md shadow-primary m-r30 btn-hover-1"
          >
            <span>Order Online</span>
          </div>
          <div
            onClick={() => navigate("/menu")}
            className="btn btn-outline-primary btn-md shadow-primary btn-hover-1"
          >
            <span>View Menu</span>
          </div>
        </div>
      </div>
    </Container>
  </div>
</SwiperSlide>

          )
        )}
      </Swiper>
    </div>
  );
};

export default MainBanner2;
