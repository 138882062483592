import { IMAGES } from "../constent/theme"
import { Link } from "react-router-dom"
import emailjs from "@emailjs/browser"
import toast, { Toaster } from "react-hot-toast"
import { useRef, useState } from "react"

const Footer2 = () => {
  const heartRef = useRef(null)
  const [input, setInput] = useState("")
  const form = useRef(null)
  const sendEmail = e => {
    e.preventDefault()
    setInput("")
    if (form.current) {
      emailjs
        .sendForm(
          "emailId",
          "template_0byuv32",
          form.current,
          "qUDIPykc776NYHv4m"
        )
        .then(
          () => {
            toast.success("Successfully send!")
          },
          error => {
            toast.error(error.text)
          }
        )
    }
  }
  return (
    <>
      <Toaster position="bottom-right" reverseOrder={true} />
      <footer className="site-footer style-2 mt-5" id="footer">
        <div className="footer-bg-wrapper" id="app-banner">
          <div className="footer-top">
            <div className="container">
            
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 wow fadeInUp">
                  <div className="widget widget_getintuch">
                    <h5 className="footer-title text-black">Contact</h5>
                    <ul>
                      <li>
                        <i className="flaticon-placeholder"></i>
                        <a className="text-black" href="https://www.google.com/maps/place/3531+Peachtree+Industrial+Blvd,+Duluth,+GA+30096/@34.0029248,-84.1729395,17z/data=!3m1!4b1!4m6!3m5!1s0x88f5a211f2e25533:0x62357f8319acf963!8m2!3d34.0029204!4d-84.1703646!16s%2Fg%2F11b8v64j48?entry=ttu" target="">
                        3531 Peachtree Industrial Blvd, Duluth, GA 30096
                        </a>
                      </li>
                      <li>
                        <i className="flaticon-telephone"></i>
                        <a href="tel:+14703592434" className="text-black">
                        (470)-359-2434
                        
                        </a>
                      </li>
                      <li>
                      <p className="text-black">
        Mon - Thur: 10:30-9:00PM<br />
        Fri - Sat: 10:30-10:00PM<br />
        Sun: 11:30-9:00PM
      </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-2 col-md-6 col-sm-6">
                  <div className="widget widget_services">
                    <h5 className="footer-title text-black  ">Our Links</h5>
                    <ul>
                      <li>
                        <Link to="/">
                          <span className="text-black">Home</span>
                        </Link>
                      </li>
                      {/* <li>
                        <Link to="/about-us">
                          <span className="text-black">About Us</span>
                        </Link>
                      </li> */}
                      
                      <li>
                        <Link to="/online-order">
                          <span className="text-black">Online Order</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/menu">
                          <span className="text-black">Menu</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                  <div className="widget widget_services">
                    <h5 className="footer-title text-black">OUR SERVICES</h5>
                    <ul>
                  
                      <li>
                        <Link to="/">
                          <span className="text-black">Top Chefs</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact-us">
                          <span className="text-black">Services</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <span  className="text-black">Pickup In Store</span>
                        </Link>
                      </li>
                     
                    </ul>
                  </div>
                </div>
                  
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="footer-bottom">
            <div className="row">
                <span className="copyright-text">
                  Crafted With{" "}
                  <span
                    className="heart"
                    ref={heartRef}
                    onClick={() => {
                      heartRef.current?.classList.toggle("heart-blast")
                    }}
                  ></span>{" "}
                  by{" "}
                  <Link to="/">
                    Hutao Online
                  </Link>
                </span>
              </div>
              
            </div>
        </div>
      </footer>
    </>
  )
}

export default Footer2
