import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

function Order() {
    const { id, restaurant_id } = useParams();
    const [order, setOrder] = useState(null);

    useEffect(() => {
        const fetchOrder = async () => {
            try {
                const res = await fetch(`${process.env.REACT_APP_API_URL}/api/orders/order/${id}/${restaurant_id}`);
                const data = await res.json();
                setOrder(data);
            } catch (error) {
                console.error("Error fetching order:", error);
            }
        };
        fetchOrder();
    }, [id, restaurant_id]);

    return (
        <div className="container mt-5">
            <div className="mt-5 mb-4 text-center">
                <Link to={`/`} className="btn btn-primary">Order More</Link>
            </div>
            <h1 className="text-center mb-4">Order Receipt {order?.order_id}</h1>
            {order && (
                <div className="card mb-4">
                    <div className="card-header">
                        <h5>Order Information</h5>
                    </div>
                    <div className="card-body">
                        <p className="text-danger fs-5"><strong>Order ID:</strong> {order.order_id}</p>
                        <p><strong>Name:</strong> {order.name}</p>
                        <p><strong>Phone Number:</strong> {order.phone_number}</p>
                        <p><strong>Total:</strong> ${order.order_total}</p>
                        <p><strong>Payment Method:</strong> {order.payment_method}</p>
                        <p><strong>Order Status:</strong> {order.order_status}</p>
                        <p><strong>Address:</strong> {order.address}</p>
                        <p><strong>Order Date:</strong> {new Date(order.createdAt).toLocaleString()}</p>
                    </div>
                </div>
            )}
            {order && order.OrderItems && order.OrderItems.map((item, index) => (
                <div className="card mb-4" key={item.id}>
                    <div className="card-header">
                        <h5>{index + 1}. {item.Item.name}</h5>
                    </div>
                    <div className="card-body">
                        <p><strong>Price:</strong> ${item.item_price}</p>
                        <p><strong>Quantity:</strong> {item.quantity}</p>
                        <p><strong>Special Instructions:</strong> {item.special_instructions}</p>
                        {item.Order_Item_Attributes && item.Order_Item_Attributes.map(attr => (
                            <p key={attr.id}>
                                <strong>{attr.Item_Attribute_Option.name}</strong>
                            </p>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Order;
